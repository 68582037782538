import { usePublicConfig } from '@pv/common/providers';
import { map } from 'lodash';
import { z } from 'zod';

const StateDataSchema = z.record(z.record(z.string()));

export const useStates = (address: { country?: string | null }) => {
  const { publicConfig } = usePublicConfig();

  const stateDataByCountryCode = StateDataSchema.parse(
    JSON.parse(publicConfig.stateDataByCountryCodeJson)
  );

  return map(
    address.country
      ? stateDataByCountryCode[address.country]
      : stateDataByCountryCode.USA,
    (stateName, stateCode) => [stateCode, stateName]
  );
};
