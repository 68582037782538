import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVertical } from 'react-feather';
import { PlannerDrawer } from '../PlannerDrawer';

export const ContactItemMenu = ({
  plannerId,
  onRemove,
  onMakePrimary,
}: {
  plannerId: string;
  onRemove?: () => void;
  onMakePrimary?: () => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openPlannerDrawer, setOpenPlannerDrawer] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="Contact actions"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        data-cy="event-drawer-contact-actions"
        className={`contact-item-menu-button ${open ? 'menu-open' : ''}`}
      >
        <MoreVertical />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableAutoFocusItem
        closeAfterTransition={true}
      >
        <MenuItem
          onClick={() => {
            setOpenPlannerDrawer(true);
            handleClose();
          }}
          data-cy="edit-contact-button"
        >
          Edit Contact
        </MenuItem>
        {onRemove && <MenuItem onClick={onRemove}>Remove Contact</MenuItem>}
        {onMakePrimary && (
          <MenuItem onClick={onMakePrimary}>Make Primary Contact</MenuItem>
        )}
      </Menu>
      <PlannerDrawer
        open={openPlannerDrawer}
        onClose={() => setOpenPlannerDrawer(false)}
        plannerId={plannerId}
      />
    </>
  );
};
