import { useQuery } from '@apollo/client';
import {
  Button,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Box } from '@mui/material';
import join from 'lodash/join';
import compact from 'lodash/compact';
import first from 'lodash/first';
import map from 'lodash/map';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import validator from 'validator';
import { phonePrint } from '@pv/common/utils';
import { AccountInput, Fields } from '@pv/common/components';
import { PVAvatar } from './Avatar';
import { plannerSearchQuery } from '@pv/common/graphql';
import { useVenues } from '../providers/venues';
import ContactsDropdownNewContactButton from './ContactsDropdownNewContactButton';
import { PhoneField } from './PhoneField';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'flex-end',
  },
  content: {
    overflowY: 'auto',
    maxHeight: '80vh',
  },
  editor: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: '60%',
    maxWidth: '100%',
  },
  popper: {
    zIndex: theme.zIndex.modal + 100,
  },
  popperContent: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
}));

const ViewContact = ({ planner }) => {
  const firstName = planner?.firstName;
  const lastName = planner?.lastName;
  const fullName = join(compact([firstName, lastName]), ' ');
  const email = planner?.email;
  const phone = planner?.phone;
  const accountName = planner?.account?.name;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <PVAvatar
          height={60}
          fullName={planner?.fullName || fullName}
          imageUrl={planner?.gravatarUrl}
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <Typography>
          <strong>{fullName}</strong>
          <br />
          {email}
          <br />
          {phonePrint(phone)}
        </Typography>
        <Typography>{accountName}</Typography>
      </Grid>
      <Grid item sm={3} xs={false} />
      <Grid item sm={9} xs={12}>
        <Typography>Past Events: {planner?.pastEventCount}</Typography>
      </Grid>
    </Grid>
  );
};

const EditContact = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  accountName,
  setAccountName,
  onClickUnlinkContact,
  forceValidate,
}) => {
  const { venues } = useVenues();
  const venue = first(venues);
  const organizationId = venue?.organization?.id;

  useEffect(() => {
    if (forceValidate && !firstName) {
      const variant = 'error';
      enqueueSnackbar('First Name Required', { variant });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceValidate]);

  useEffect(() => {
    if (forceValidate && email && !validator.isEmail(email)) {
      const variant = 'error';
      enqueueSnackbar('Email is invalid', { variant });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceValidate]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Fields.PvTextField
          label="First Name"
          name="searchName"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
          forceValidate={forceValidate}
        />
      </Grid>
      <Grid item xs={6}>
        <Fields.PvTextField
          label="Last Name"
          name="searchName"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Fields.PvTextField
          label="Email"
          name="searchName"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          forceValidate={forceValidate}
          forceError={forceValidate && email && !validator.isEmail(email)}
        />
      </Grid>
      <Grid item xs={12}>
        <PhoneField
          label="Phone"
          name="phone"
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <AccountInput
          organizationId={organizationId}
          label="Account"
          value={accountName}
          onChange={setAccountName}
        />
      </Grid>
      {onClickUnlinkContact && (
        <Grid item xs={12}>
          <Button
            style={{ float: 'right' }}
            variant="contained"
            onClick={onClickUnlinkContact}
          >
            Unlink Contact
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const SearchContact = ({
  onClickSearchResult,
  onClickCreateNewPlanner,
  forceValidate,
}) => {
  const { venues } = useVenues();
  const classes = useStyles();
  const [query, setQuery] = useState('');
  const openSearchPopover = !!query;
  const searchRef = useRef();

  useEffect(() => {
    if (forceValidate && !query) {
      const variant = 'error';
      enqueueSnackbar('Contact Name Required', {
        variant,
        SnackbarProps: {
          'data-cy': 'contact-first-name-required-alert',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceValidate]);

  const organizationId = first(venues)?.organization.id;
  const variables = { organizationId, query, pageSize: 4 };
  const skip = !organizationId || query === '';
  const { data: searchData, loading } = useQuery(plannerSearchQuery, {
    variables,
    skip,
  });
  const plannerSearch = searchData?.plannerSearch;

  const handleSearchPopverClose = () => {
    setQuery('');
  };

  const onChangeQuery = (e) => {
    const v = e.target.value;
    setQuery(v);
  };

  const SearchResults = () => (
    <Popper
      placement="bottom-start"
      anchorEl={searchRef.current}
      className={classes.popper}
      open={openSearchPopover}
      transition
    >
      <ClickAwayListener onClickAway={handleSearchPopverClose}>
        <Paper className={classes.popperContent} elevation={3}>
          <List>
            {map(plannerSearch, (planner) => (
              <ListItem
                className="planner-search-result"
                key={planner.id}
                button
                role="option"
                onClick={() => onClickSearchResult(planner)}
              >
                <ListItemText>
                  <div>{planner.fullName}</div>
                  <Typography color="textSecondary">
                    {planner.phone && phonePrint(planner.phone)}{' '}
                    {planner.email && `(${planner.email})`}
                  </Typography>
                </ListItemText>
              </ListItem>
            ))}
          </List>
          {!loading && (
            <>
              <Divider />
              <ContactsDropdownNewContactButton
                query={query}
                onClick={onClickCreateNewPlanner}
              />
            </>
          )}
        </Paper>
      </ClickAwayListener>
    </Popper>
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div ref={searchRef}>
            <Fields.PvTextField
              forceValidate={forceValidate}
              required
              value={query}
              onChange={onChangeQuery}
              label="First Name"
              name="plannerSearch"
              data-cy="create-event-contact-first-name-text-field"
            />
          </div>
        </Grid>
      </Grid>
      <SearchResults />
    </>
  );
};

export const NewContactArea = ({
  title = 'Contact',
  initial,
  contactView,
  planner,
  setPlanner,
  onClickUnlinkContact,
  onClickSearchResult,
  buttons,
  ...props
}) => (
  <Box
    sx={{
      borderRadius: '8px',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      padding: '16px',
    }}
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Add Contact</Typography>
      </Grid>

      {contactView === 'view' && (
        <Grid item xs={12}>
          <ViewContact
            onClickUnlinkContact={onClickUnlinkContact}
            planner={planner}
            {...props}
          />
        </Grid>
      )}
      {contactView === 'edit' && (
        <Grid item xs={12}>
          <EditContact onClickUnlinkContact={onClickUnlinkContact} {...props} />
        </Grid>
      )}
      {contactView === 'search' && (
        <Grid item xs={12}>
          <SearchContact onClickSearchResult={onClickSearchResult} {...props} />
        </Grid>
      )}
    </Grid>
  </Box>
);

export default NewContactArea;
