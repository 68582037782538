import React, { useEffect, useState } from 'react';
import { Select, Grid, MenuItem, FormControl, InputLabel } from '@mui/material';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import { moneyPrintWithoutZeros } from '@pv/common/utils';

export const PlanOptionsSelect = ({
  includeStarter = false,
  availablePlans,
  selectedPlan,
  setSelectedPlan,
  initialBillingInterval,
  initialTier,
}) => {
  const [planTier, setPlanTier] = useState(
    initialTier || selectedPlan?.tier || 'professional'
  );

  let availableTiers = uniq(map(sortBy(availablePlans, 'monthlyRate'), 'tier'));

  if (!includeStarter) {
    availableTiers = filter(availableTiers, (tier) => tier !== 'starter');
  }

  const selectTierOptions = availableTiers.map((tier) => [
    tier,
    startCase(tier),
    false,
  ]);

  const monthlyRatesByTier = {};
  availablePlans.forEach((plan) => {
    const update = monthlyRatesByTier[plan.tier] || {};
    return (monthlyRatesByTier[plan.tier] = {
      ...update,
      ...{ [plan.billingInterval]: plan.monthlyRate / 100 },
    });
  });

  const tierRates = monthlyRatesByTier[planTier];
  const monthlyRate = tierRates?.monthly;
  const annualRate = tierRates?.annual;

  const annualDiscount = tierRates?.monthly * 12 - tierRates?.annual * 12;

  const selectIntervalOptions = [];
  if (monthlyRate) {
    selectIntervalOptions.push([
      'monthly',
      `Month-to-Month ($${monthlyRate}/mo)`,
      false,
    ]);
  }
  if (annualRate) {
    let annualDiscountText = `Yearly (${moneyPrintWithoutZeros(
      annualRate
    )}/mo)`;
    if (annualDiscount && annualDiscount > 0) {
      annualDiscountText = `${annualDiscountText} - Save ${moneyPrintWithoutZeros(
        annualDiscount
      )}/yr 🎉`;
    }

    selectIntervalOptions.push([
      'annual',
      annualDiscountText,
      planTier === 'starter',
    ]);
  }

  const [planBillingInterval, setPlanBillingInterval] = useState(
    initialBillingInterval ||
      selectedPlan?.billingInterval ||
      selectIntervalOptions?.[0]?.[0] ||
      'monthly'
  );

  useEffect(() => {
    let plan;
    if (planTier === 'starter') {
      // billingInterval is always monthly for starter plan
      plan = availablePlans.find(
        (p) => p.tier === planTier && p.billingInterval === 'monthly'
      );
    } else {
      plan = availablePlans.find(
        (p) => p.tier === planTier && p.billingInterval === planBillingInterval
      );
    }
    setSelectedPlan(plan);
  }, [planTier, planBillingInterval, availablePlans, setSelectedPlan]);

  return (
    <>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="plan-name-select-label">Plan Name</InputLabel>
          <Select
            labelId="plan-name-select-label"
            name="plan-name"
            value={planTier}
            onChange={(e) => setPlanTier(e.target.value)}
            label="Plan Name"
          >
            {selectTierOptions.map(([id, label]) => (
              <MenuItem key={id} value={id}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {planTier !== 'starter' && (
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="billing-period-select-label">
              Billing Period
            </InputLabel>
            <Select
              labelId="billing-period-select-label"
              name="billing-period"
              value={planBillingInterval}
              onChange={(e) => setPlanBillingInterval(e.target.value)}
              label="Billing Period"
            >
              {selectIntervalOptions.map(([id, label]) => (
                <MenuItem key={id} value={id}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
    </>
  );
};
