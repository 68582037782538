import React from 'react';
import { Badge } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Planner, User } from '@pv/common/graphql/types';
import { PVAvatar } from '../../components/Avatar';

const InboxBadge = styled(Badge)`
  & .MuiBadge-badge {
    height: 12px;
    min-width: 12px;
  }

  & .MuiBadge-standard {
    height: 12px;
    top: 2px;
    left: 22px;
    width: 12px;
  }
`;

export const InboxItemAvatar = ({
  person,
  bgcolor = 'primary.main',
  unreadMessageCount = 0,
}: {
  person: Partial<Planner> | Partial<User>;
  bgcolor?: string;
  unreadMessageCount?: number;
}) => {
  return unreadMessageCount && unreadMessageCount > 0 ? (
    <InboxBadge badgeContent="" color="error">
      <PVAvatar
        imageUrl={person.gravatarUrl || ''}
        fullName={person.fullName || ''}
        backgroundColor={bgcolor}
      />
    </InboxBadge>
  ) : (
    <PVAvatar
      imageUrl={person.gravatarUrl || ''}
      fullName={person.fullName || ''}
      backgroundColor={bgcolor}
    />
  );
};
