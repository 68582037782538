import { usePublicConfig } from '@pv/common/providers';
import { map } from 'lodash';
import { z } from 'zod';

const CountryDataSchema = z.record(z.string());

export const useCountries = () => {
  const { publicConfig } = usePublicConfig();

  const countryDataByCountryCode = CountryDataSchema.parse(
    JSON.parse(publicConfig.countryDataByCountryCodeJson)
  );

  let countries = map(countryDataByCountryCode, (countryName, countryCode) => [
    countryCode,
    countryName,
  ]);

  return countries;
};
