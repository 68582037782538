import React from 'react';
import {
  Button,
  useMediaQuery,
  useTheme,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';

import { useLocation, Link as RouterLink } from 'react-router-dom';
import {
  BarChart2,
  CreditCard,
  DollarSign,
  FileText,
  List,
  Users,
} from 'react-feather';
import BonusReportsMenu from './BonusReportsMenu';
const secondaryAppbarHeight = 42;

const Tab = ({ children, className, href, ...rest }) => {
  const location = useLocation();
  const { pathname } = location;

  const styles = {
    secondaryTab: {
      color: 'rgba(59, 57, 108, 0.6)',
      textTransform: 'none',
      height: secondaryAppbarHeight,
      borderRadius: 0,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '24px',
      borderTop: `2px solid transparent`,
      borderBottom: `2px solid transparent`,
      padding: '9px 16px',
      gap: '6px',
    },
    selectedSecondaryTab: {
      color: 'rgba(39, 43, 100, 1)',
      borderBottom: `2px solid rgba(39, 43, 100, 1)`,
    },
  };

  const selected = pathname === href;
  const sx = selected
    ? { ...styles.secondaryTab, ...styles.selectedSecondaryTab }
    : styles.secondaryTab;

  return (
    <Button size="small" component={RouterLink} sx={sx} to={href} {...rest}>
      {children}
    </Button>
  );
};

const SecondaryAppBar = () => {
  const theme = useTheme();
  const location = useLocation();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const reportVals = [
    {
      path: '/reports/analytics',
      key: 'reports_analytics',
      startIcon: <BarChart2 size={20} />,
      label: 'Analytics',
    },
    {
      path: '/reports/events',
      key: 'reports_events',
      startIcon: <List size={20} />,
      label: 'Events',
    },
    {
      path: '/reports/payments',
      key: 'reports_payments',
      startIcon: <CreditCard size={20} />,
      label: 'Payments',
    },
    {
      path: '/reports/proposal_data',
      key: 'reports_proposal_data',
      startIcon: <FileText size={20} />,
      label: 'Proposals',
    },
    {
      path: '/reports/contacts',
      key: 'reports_contacts',
      startIcon: <Users size={20} />,
      label: 'Contacts',
    },
    {
      path: '/reports/accounts',
      key: 'reports_accounts',
      startIcon: <Users size={20} />,
      label: 'Accounts',
    },
    {
      path: '/reports/sales',
      key: 'reports_sales',
      startIcon: <DollarSign size={20} />,
      label: 'Sales',
    },
  ];

  const { pathname } = location;
  const navValue = reportVals.find((val) => val.path === pathname)?.path;

  if (smallScreen) {
    return (
      <Stack
        direction="row"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: secondaryAppbarHeight,
          minHeight: secondaryAppbarHeight,
          padding: '0px 20px',
          gap: '12px',
          backgroundColor: 'white',
        }}
      >
        <Select size="small" value={navValue} vals={reportVals}>
          {reportVals.map((val) => (
            <MenuItem
              key={val.path}
              value={val.path}
              component={RouterLink}
              to={val.path}
            >
              {val.label}
            </MenuItem>
          ))}
        </Select>
        <BonusReportsMenu />
      </Stack>
    );
  }
  return (
    <Stack
      direction="row"
      sx={{
        height: secondaryAppbarHeight,
        minHeight: secondaryAppbarHeight,
        padding: '0px 20px',
        gap: '12px',
        backgroundColor: 'white',
        alignItems: 'center',
      }}
    >
      {reportVals.map((val) => (
        <Tab key={val.key} href={val.path}>
          {val.startIcon}
          {val.label}
        </Tab>
      ))}
      <BonusReportsMenu />
    </Stack>
  );
};

export default SecondaryAppBar;
