import React from 'react';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

// TODO: use this in the venue profile
type Address = {
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};
export const AddressFields = ({
  address,
  setAddress,
  states,
  countries,
}: {
  address: Address;
  setAddress: (address: Address) => void;
  states: string[][];
  countries: string[][];
}) => {
  return (
    <>
      <Grid item xs={12}>
        <TextField
          value={address.streetAddress1}
          name="streetAddress1"
          onChange={(e) =>
            setAddress({
              ...address,
              streetAddress1: e.target.value,
            })
          }
          label="Street Address 1"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          value={address.streetAddress2}
          name="streetAddress2"
          onChange={(e) =>
            setAddress({
              ...address,
              streetAddress2: e.target.value,
            })
          }
          label="Street Address 2"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          value={address.city}
          name="city"
          onChange={(e) =>
            setAddress({
              ...address,
              city: e.target.value,
            })
          }
          label="City"
          fullWidth
        />
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id="state-label">State</InputLabel>
          <Select
            labelId="state-label"
            value={address.state}
            onChange={(e) => setAddress({ ...address, state: e.target.value })}
          >
            <MenuItem value=""></MenuItem>
            {states.map((state) => (
              <MenuItem key={state[0]} value={state[0]}>
                {state[1]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <TextField
          value={address.zip}
          name="zip"
          onChange={(e) =>
            setAddress({
              ...address,
              zip: e.target.value,
            })
          }
          label="Zip"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            labelId="country-label"
            value={address.country}
            onChange={(e) =>
              setAddress({ ...address, country: e.target.value })
            }
          >
            <MenuItem value=""></MenuItem>
            {countries.map((country) => (
              <MenuItem key={country[0]} value={country[0]}>
                {country[1]}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};
