import { gql, useMutation } from '@apollo/client';
import { enqueueSnackbar } from 'notistack';
import {
  RemoveAdditionalContactMutation,
  RemoveAdditionalContactMutationVariables,
  ReplaceEventPrimaryContactMutation,
  ReplaceEventPrimaryContactMutationVariables,
  EventContactsQuery,
  EventContactsQueryVariables,
  UpdateEventPrimaryContactMutation,
  UpdateEventPrimaryContactMutationVariables,
} from '@pv/common/graphql/operations';
import { eventContactsQuery } from '../../hooks/useEventContactsQuery';
import { useEvent } from '../../providers/event';

export const removeAdditionalContactMutation = gql`
  mutation RemoveAdditionalContact($input: RemoveAdditionalContactInput!) {
    removeAdditionalContact(input: $input) {
      planner {
        id
        fullName
        email
        phone
      }
      errors {
        message
      }
    }
  }
`;

export const useRemoveAdditionalContact = (plannerId: string) => {
  const { event } = useEvent();
  const eventId = event?.id;

  return useMutation<
    RemoveAdditionalContactMutation,
    RemoveAdditionalContactMutationVariables
  >(removeAdditionalContactMutation, {
    onCompleted: () => {
      enqueueSnackbar('Contact removed', {
        variant: 'success',
      });
    },
    update: (cache) => {
      if (!eventId) {
        return;
      }

      cache.updateQuery<EventContactsQuery, EventContactsQueryVariables>(
        {
          query: eventContactsQuery,
          variables: { eventId },
        },
        (data) => {
          if (!data || !data.event) {
            return;
          }

          return {
            event: {
              ...data.event,
              additionalContacts: data.event.additionalContacts.filter(
                (contact) => contact.id !== plannerId
              ),
            },
          };
        }
      );
    },
  });
};

export const updateEventPrimaryContactMutation = gql`
  mutation UpdateEventPrimaryContact($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      event {
        id
        planner {
          id
          fullName
          email
          phone
          account {
            name
          }
        }
      }
    }
  }
`;

export const useReplaceEventPrimaryContact = (plannerId: string) => {
  const { event } = useEvent();
  const eventId = event?.id;

  return useMutation<
    ReplaceEventPrimaryContactMutation,
    ReplaceEventPrimaryContactMutationVariables
  >(replaceEventPrimaryContactMutation, {
    onCompleted: () => {
      enqueueSnackbar('Changed primary contact', { variant: 'success' });
    },
    update: (cache, { data }) => {
      if (
        !eventId ||
        !data ||
        !data.createAdditionalContact ||
        !data.createAdditionalContact.planner
      ) {
        return;
      }

      const newAdditionalContact = data.createAdditionalContact.planner;

      cache.updateQuery<EventContactsQuery, EventContactsQueryVariables>(
        {
          query: eventContactsQuery,
          variables: { eventId },
        },
        (data) => {
          if (!data || !data.event) {
            return;
          }

          return {
            event: {
              ...data.event,
              additionalContacts: [
                ...data.event.additionalContacts.filter(
                  (contact) => contact.id !== plannerId
                ),
                newAdditionalContact,
              ],
            },
          };
        }
      );
    },
  });
};

export const replaceEventPrimaryContactMutation = gql`
  mutation ReplaceEventPrimaryContact(
    $updateEventInput: UpdateEventInput!
    $createAdditionalContactInput: CreateAdditionalContactInput!
  ) {
    updateEvent(input: $updateEventInput) {
      event {
        id
        planner {
          id
          fullName
          email
          phone
          account {
            id
            name
          }
        }
      }
    }
    createAdditionalContact(input: $createAdditionalContactInput) {
      planner {
        id
        fullName
        email
        phone
        account {
          id
          name
        }
      }
    }
  }
`;

export const useUpdateEventPrimaryContact = () => {
  const { event } = useEvent();
  const eventId = event?.id;

  return useMutation<
    UpdateEventPrimaryContactMutation,
    UpdateEventPrimaryContactMutationVariables
  >(updateEventPrimaryContactMutation, {
    onCompleted: () => {
      enqueueSnackbar('Changed primary contact', { variant: 'success' });
    },
    update: (cache, { data }) => {
      if (!eventId || !data?.updateEvent?.event?.planner) {
        return;
      }

      const newPrimaryContact = data.updateEvent.event.planner;

      cache.updateQuery<EventContactsQuery, EventContactsQueryVariables>(
        {
          query: eventContactsQuery,
          variables: { eventId },
        },
        (data) => {
          if (!data || !data.event) {
            return;
          }

          return {
            event: {
              ...data.event,
              additionalContacts: data.event.additionalContacts.filter(
                (contact) => contact.id !== newPrimaryContact.id
              ),
            },
          };
        }
      );
    },
  });
};
