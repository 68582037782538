import { useUserAuth } from '../providers/auth';
import { useVenues } from '../providers/venues';

export const useSwitchVenues = () => {
  const { user } = useUserAuth();
  const { setCurrentVenueMemberships } = useVenues();

  const switchToAllVenuesMode = ({ redirect }: { redirect: boolean }) => {
    if (user) {
      setCurrentVenueMemberships(user.activeVenueMemberships, redirect);
    }
  };

  const switchToSingleVenueMode = ({
    venueId,
    redirect = false,
  }: {
    venueId: string;
    redirect: boolean;
  }) => {
    const venueMembership = user?.activeVenueMemberships.find(
      (vm) => vm.venue.id === venueId
    );
    if (venueMembership) {
      setCurrentVenueMemberships([venueMembership], redirect);
    }
  };

  return { switchToAllVenuesMode, switchToSingleVenueMode };
};
