import React, { useEffect, useMemo, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  Box,
  Stack,
  Backdrop,
  ClickAwayListener,
  Divider,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { phonePrint } from '@pv/common/utils';

import some from 'lodash/some';
import map from 'lodash/map';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Fields } from '@pv/common/components';
import { PVAvatar } from './Avatar';
import { Search, X } from 'react-feather';
import { PVHoverNavy } from '@pv/common/colors';

import { useVenues } from '../providers/venues';
import StatusChip from './StatusChip';

const multisearchQuery = gql`
  query Multisearch($venueIds: [ID!]!, $query: String!) {
    multisearch(venueIds: $venueIds, query: $query) {
      id
      query
      events {
        id
        name
        status
        startDate
        startOffset
        endDate
        endOffset
        planner {
          id
          fullName
          phone
          email
          gravatarUrl
        }
        additionalContacts {
          id
          fullName
          phone
          email
          gravatarUrl
        }
      }
    }
  }
`;

const Planner = ({ planner }) => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        paddingBottom: '8px',
      }}
    >
      <PVAvatar imageUrl={planner.gravatarUrl} fullName={planner.fullName} />
      <Stack direction="column">
        <Typography variant="subtitle1">{planner.fullName}</Typography>
        <Typography variant="body2" color="textSecondary">
          {phonePrint(planner.phone)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {planner.email}
        </Typography>
      </Stack>
    </Box>
  );
};

const SearchResultItem = ({ event, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <li
      className="event-search-result"
      button
      key={event.id}
      onClick={onClick}
      style={{
        alignItems: 'flex-start',
        padding: '20px',
        cursor: 'pointer',
        backgroundColor: isHovered ? PVHoverNavy : 'inherit',
        borderBottom: '1px solid #E6E6E6',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
              padding: '0px 8px 16px 0px',
            }}
          >
            <Typography variant="subtitle1">{event.name}</Typography>
            {event.startDate && (
              <div>
                <Typography variant="body2">
                  {moment(event.startDate)
                    .startOf('day')
                    .add(event.startOffset, 'minutes')
                    .format('MMM D, YYYY')}
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div>
          <StatusChip status={event.status} />
        </div>
      </Box>
      <Box display="flex" flexDirection="column">
        <Planner planner={event.planner} />
        {event.additionalContacts.map((contact) => (
          <Planner planner={contact} />
        ))}
      </Box>
    </li>
  );
};

const SearchResults = ({ open, onClose }) => {
  const { venues, venueIds } = useVenues();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [noMatches, setNoMatches] = useState(false);
  const variables = { venueIds, query };
  const skip = !venues || !query;
  const { data, loading } = useQuery(multisearchQuery, { variables, skip });
  const matchingDocs = useMemo(() => {
    const matchingEvents = data?.multisearch?.events || [];
    return [...matchingEvents];
  }, [data]);

  const onClickSearchAndClose = (event) => {
    onClose();
    navigate(`/events/${event.id}`);
  };

  useEffect(() => {
    if (!loading && query) {
      if (some(matchingDocs)) {
        setNoMatches(false);
      } else {
        setNoMatches(true);
      }
    }
  }, [loading, matchingDocs, query]);

  useEffect(() => {
    if (open) {
      setQuery('');
    }
  }, [open]);
  const left = window.innerWidth / 2;
  const top = smallScreen ? 0 : 200;
  return (
    <Backdrop
      open={open}
      sx={{
        zIndex: theme.zIndex.modal - 1,
      }}
    >
      <Popover
        open={open}
        anchorReference="anchorPosition"
        anchorPosition={{ top, left }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          zIndex: theme.zIndex.modal + 100,
        }}
      >
        <ClickAwayListener onClickAway={onClose}>
          <Paper
            elevation={6}
            sx={{
              width: '100%',
              overflowY: 'hidden',
              [theme.breakpoints.up('md')]: {
                width: 500,
              },
            }}
          >
            <Box
              sx={{
                padding: theme.spacing(2),
              }}
            >
              <Fields.PvTextField
                autoFocus
                inputProps={{
                  'aria-label': 'Search Events',
                }}
                placeholder="Search Events"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                disableUnderline
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={onClose}>
                        <X />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Divider />
            <div style={{ height: 4 }}>
              {loading && <LinearProgress variant="query" />}
            </div>
            <Box
              sx={{
                maxHeight: 460,
                overflowY: 'scroll',
              }}
            >
              <ul
                style={{
                  listStyle: 'none',
                  padding: 0,
                  margin: 0,
                }}
              >
                {noMatches && (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography>No Matches</Typography>
                  </div>
                )}
                {map(matchingDocs, (event) => (
                  <SearchResultItem
                    key={event.id}
                    event={event}
                    onClick={() => onClickSearchAndClose(event)}
                  />
                ))}
              </ul>
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popover>
    </Backdrop>
  );
};

export default SearchResults;
