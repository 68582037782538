import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import {
  ListItem,
  ListItemText,
  Typography,
  Autocomplete,
  TextField,
} from '@mui/material';
import moment from 'moment';

import { useVenues } from '../providers/venues';
import {
  EventSearchResultsQuery,
  EventSearchResultsQueryVariables,
} from '@pv/common/graphql/operations';

type Event = EventSearchResultsQuery['multisearch']['events'][0];

const multisearchQuery = gql`
  query EventSearchResults($venueIds: [ID!]!, $query: String!) {
    multisearch(venueIds: $venueIds, query: $query) {
      id
      query
      events {
        id
        name
        startDate
        startOffset
        endDate
        endOffset
        planner {
          id
          fullName
        }
      }
    }
  }
`;

interface Props {
  event?: Event;
  onSelectEvent: (event: Event) => void;
}

const renderDate = (event: Event) => {
  if (event.startDate) {
    return (
      <span>
        (
        {moment(event.startDate)
          .startOf('day')
          .add(event.startOffset, 'minutes')
          .format('MMM D, YYYY')}
        )
      </span>
    );
  }
  return null;
};

export const EventSearch = ({ event, onSelectEvent }: Props) => {
  const [query, setQuery] = useState<string>('');

  const { venues, venueIds } = useVenues();
  const variables: EventSearchResultsQueryVariables = { venueIds, query };
  const skip = !venues || !query;
  const { data } = useQuery<EventSearchResultsQuery>(multisearchQuery, {
    variables,
    skip,
  });
  const results = data?.multisearch?.events || [];

  return (
    <Autocomplete
      options={results}
      value={event}
      inputValue={query}
      onInputChange={(_, newInputValue) => setQuery(newInputValue)}
      onChange={(_, event) => {
        if (event) {
          onSelectEvent(event);
        }
      }}
      getOptionLabel={(option) =>
        option.name || option.planner?.fullName || option.startDate
      }
      renderOption={(props, event) => (
        <ListItem
          className="event-search-result"
          onClick={() => onSelectEvent(event)}
          {...props}
        >
          <ListItemText>
            <div>{event.planner?.fullName}</div>
            <Typography color="textSecondary">
              {event.name} {renderDate(event)}
            </Typography>
          </ListItemText>
        </ListItem>
      )}
      renderInput={(params) => <TextField {...params} required label="Event" />}
    />
  );
};
