import React, { useCallback } from 'react';
import { Stack, Typography, Link, Box } from '@mui/material';
import { PVAvatar } from '../Avatar';
import { phonePrint } from '@pv/common/utils';
import { ContactItemMenu } from './Menu';
import { useEvent } from '../../providers/event';
import {
  useRemoveAdditionalContact,
  useUpdateEventPrimaryContact,
  useReplaceEventPrimaryContact,
} from './graphql';

export const ContactItem = ({
  id,
  fullName,
  email,
  phone,
  imageUrl,
  accountName,
  readOnly = false,
  canRemove,
  primaryContactId,
  firstAdditionalContactId,
}: {
  id: string;
  fullName: string;
  email?: string;
  phone?: string;
  imageUrl?: string;
  accountName?: string;
  readOnly?: boolean;
  canRemove: boolean;
  primaryContactId?: string;
  firstAdditionalContactId?: string;
}) => {
  const { event } = useEvent();
  const eventId = event?.id;
  const isPrimary = id === primaryContactId;

  const [removeAdditionalContact] = useRemoveAdditionalContact(id);
  const [replaceEventPrimaryContact] = useReplaceEventPrimaryContact(id);
  const [updateEventPrimaryContact] = useUpdateEventPrimaryContact();

  const handleMakePrimary = useCallback(() => {
    if (!eventId) return;

    replaceEventPrimaryContact({
      variables: {
        updateEventInput: { id: eventId, plannerId: id },
        createAdditionalContactInput: {
          eventId,
          plannerId: primaryContactId,
        },
      },
    });
  }, [primaryContactId, eventId, replaceEventPrimaryContact, id]);

  const handleRemove = useCallback(() => {
    if (!eventId) return;

    if (isPrimary && firstAdditionalContactId) {
      updateEventPrimaryContact({
        variables: {
          input: { id: eventId, plannerId: firstAdditionalContactId },
        },
      });
    } else {
      removeAdditionalContact({
        variables: { input: { plannerId: id, eventId } },
      });
    }
  }, [
    removeAdditionalContact,
    eventId,
    isPrimary,
    firstAdditionalContactId,
    updateEventPrimaryContact,
    id,
  ]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        '& .contact-item-menu-button': {
          opacity: 0,
        },

        '& .contact-item-menu-button.menu-open': {
          opacity: 1,
        },
        '& .contact-item-menu-button:focus': {
          opacity: 1,
        },
        '&:hover .contact-item-menu-button': {
          opacity: 1,
        },
        '&:focus-within .contact-item-menu-button': {
          opacity: 1,
        },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        <PVAvatar imageUrl={imageUrl} fullName={fullName} height={52} />
        <Stack>
          <Typography noWrap variant="subtitle1">
            {fullName}
          </Typography>
          {email && <Typography variant="body2">{email}</Typography>}
          {phone && (
            <Link underline="hover" href={`tel:${phone}`}>
              <Typography variant="body2">{phonePrint(phone)}</Typography>
            </Link>
          )}
          {accountName && (
            <Typography variant="body2">{accountName}</Typography>
          )}
        </Stack>
      </Stack>
      {!readOnly && eventId && (
        <Box>
          <ContactItemMenu
            plannerId={id}
            onRemove={canRemove ? handleRemove : undefined}
            onMakePrimary={!isPrimary ? handleMakePrimary : undefined}
          />
        </Box>
      )}
    </Stack>
  );
};
