import React, { FC, useEffect, useState } from 'react';
import { each, snakeCase, startCase } from 'lodash';
import {
  Divider,
  Grid,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { Trash2 as Trash } from 'react-feather';
import { useMutation } from '@pv/common/hooks';
import { dollarsToCents, moneyPrint } from '@pv/common/utils';
import {
  DeleteEventConfirmModal,
  Fields,
  PvDialog,
} from '@pv/common/components';
import { Event as EventType } from '@pv/common/graphql/types';
import { deleteEventMutation } from '@pv/common/graphql';

/*
 * Types.
 */

interface EventLostDialogProps {
  open: boolean;
  onClose: () => void;
  afterConfirm: () => void;
  event: EventType;
}

interface InputType {
  id: string;
  reason?: string;
  lostReasonCategories?: string[];
  refundAmount?: number;
  referralVenueName?: string;
  referralEventManagerName?: string;
  referralEventManagerEmail?: string;
}

const cancelEventMutation = gql`
  mutation CancelEvent($input: CancelEventInput!) {
    cancelEvent(input: $input) {
      event {
        id
        status
      }
      errors {
        message
      }
    }
  }
`;

/*
 * Component.
 */

export const EventLostDialog: FC<EventLostDialogProps> = ({
  open,
  onClose,
  event,
  afterConfirm,
}: EventLostDialogProps) => {
  const navigate = useNavigate();
  const [referralVenueName, setReferralVenueName] = useState('');
  const [referralEventManagerName, setReferralEventManagerName] = useState('');
  const [forceReferralValidate, setForceReferralValidate] = useState(false);
  const [referralEventManagerEmail, setReferralEventManagerEmail] =
    useState('');
  const [refundAmount, setRefundAmount] = useState('');
  const [refund, setRefund] = useState('None');
  const refunds = ['None', 'Full Refund', 'Partial Refund'];

  const [validateContactInfo, setValidateContactInfo] = useState(false);
  const [lostReasons, setLostReasons] = useState<string[]>([]);
  const [showOtherReason, setShowOtherReason] = useState(false);
  const [otherLostReason, setOtherLostReason] = useState('');
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);

  useEffect(() => {
    setReferralVenueName('');
    setReferralEventManagerName('');
    setReferralEventManagerEmail('');
    setLostReasons([]);
    setOtherLostReason('');
    setRefundAmount('');
    setRefund('None');
  }, [open]);

  const [cancelEvent] = useMutation(cancelEventMutation, {
    onNoErrorsCompleted: () => {
      enqueueSnackbar('Event Lost', { variant: 'info' });
      onClose();
      if (afterConfirm) {
        afterConfirm();
      }
    },
  });

  const [deleteEvent] = useMutation(deleteEventMutation, {
    onNoErrorsCompleted: () => {
      enqueueSnackbar('Event Deleted', { variant: 'info' });
      onClose();
      navigate(0);
    },
    onSomeErrorsCompleted: ({ deleteEvent: deleteEventResponse }) => {
      each(deleteEventResponse.errors, (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    },
  });

  const depositPayment = event?.depositPayment;
  const showRefund =
    depositPayment?.state === 'paid' && depositPayment?.type !== 'Offline';
  let fundsAvailableForRefund;
  if (depositPayment?.amount && depositPayment?.refundAmount && showRefund) {
    fundsAvailableForRefund =
      depositPayment.amount - depositPayment.refundAmount;
  }

  const onConfirm = () => {
    if (lostReasons.length === 0 && otherLostReason.length === 0) {
      setValidateContactInfo(true);
      return;
    }

    const { id } = event;
    const input: InputType = {
      id,
      lostReasonCategories: lostReasons.map((reason) => snakeCase(reason)),
      reason: otherLostReason,
    };

    if (showRefund) {
      if (refund === 'Full Refund') {
        input.refundAmount = depositPayment.amount;
      } else if (refund === 'Partial Refund') {
        input.refundAmount = dollarsToCents(refundAmount);
      }
    }

    if (
      referralVenueName ||
      referralEventManagerName ||
      referralEventManagerEmail
    ) {
      if (!referralEventManagerName || !referralEventManagerEmail) {
        setForceReferralValidate(true);
        return;
      }
      input.referralVenueName = referralVenueName;
      input.referralEventManagerName = referralEventManagerName;
      input.referralEventManagerEmail = referralEventManagerEmail;
    }

    const variables = { input };
    cancelEvent({ variables });
  };

  const title = 'Lost Reason';

  const lostReasonValues = [
    'canceled',
    'not available',
    'price',
    'chose another venue',
    'group too small',
    'group too large',
    'unresponsive',
    'other',
  ];

  const onChangeReferralEventManagerName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setForceReferralValidate(false);
    setReferralEventManagerName(e.target.value);
  };

  const onChangeReferralEventManagerEmail = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setForceReferralValidate(false);
    setReferralEventManagerEmail(e.target.value);
  };

  const onLostReasonCheckboxChanged = (
    value: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target.checked) {
      setLostReasons([...lostReasons, value]);
    } else {
      setLostReasons(lostReasons.filter((reason) => reason != value));
    }
  };

  const onDeleteEvent = () => {
    const variables = { input: { id: event.id } };
    deleteEvent({ variables });
    setShowDeleteEventModal(false);
  };

  return (
    <PvDialog
      loading={false}
      maxWidth="sm"
      onConfirm={onConfirm}
      title={title}
      open={open}
      onClose={onClose}
      tertiaryButtonText="Delete Event"
      tertiaryButtonVariant="outlined"
      tertiaryButtonIcon={<Trash color="#FE6257" size={20} />}
      onTertiaryButtonClick={() => setShowDeleteEventModal(true)}
    >
      <DeleteEventConfirmModal
        onConfirm={onDeleteEvent}
        open={showDeleteEventModal}
        onClose={() => setShowDeleteEventModal(false)}
      />
      <div className="cancelEventDialog" style={{ overflowY: 'unset' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Select a Lost Reason for internal use, not visible to guests.
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ columns: 2 }}>
            <FormGroup>
              {lostReasonValues.map((val) => (
                <FormControlLabel
                  key={val}
                  label={startCase(val)}
                  control={
                    <Checkbox
                      color="primary"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        onLostReasonCheckboxChanged(val, e);

                        if (val === 'other') {
                          setShowOtherReason(e.target.checked);
                        }
                      }}
                    />
                  }
                />
              ))}
            </FormGroup>
          </Grid>
          {showOtherReason && (
            <Grid item xs={12}>
              <Fields.PvTextField
                label="Other lost reason..."
                name="reason"
                value={otherLostReason}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOtherLostReason(e.target.value);
                }}
              />
            </Grid>
          )}
          {showRefund && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <strong>Would you like to issue a refund?</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Fields.PvDropDown
                  className="RefundPvDialog"
                  vals={refunds}
                  value={refund}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setRefund(e.target.value)
                  }
                  label="Refund"
                />
              </Grid>
              {refund === 'Partial Refund' && (
                <Grid item xs={12}>
                  <Fields.PvCurrencyMaskField
                    label="Refund Amount"
                    name="refund-amount"
                    value={refundAmount}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setRefundAmount(e.target.value)
                    }
                  />
                </Grid>
              )}
              {fundsAvailableForRefund && (
                <Grid item xs={12}>
                  <Typography>
                    Funds availabile for refund:{' '}
                    {moneyPrint(fundsAvailableForRefund / 100)}
                  </Typography>
                </Grid>
              )}
            </>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography>
              <strong>
                Would you like to refer this lead to another venue?
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Fields.PvTextField
              name="referral-venue-name"
              value={referralVenueName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setReferralVenueName(e.target.value)
              }
              label="Venue Name"
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
          >
            <Fields.PvTextField
              name="referral-event-manager-name"
              value={referralEventManagerName}
              onChange={onChangeReferralEventManagerName}
              label="Name"
              forceValidate={forceReferralValidate}
            />
            <Fields.PvTextField
              name="referral-event-manager-email"
              value={referralEventManagerEmail}
              onChange={onChangeReferralEventManagerEmail}
              label=" Email"
              forceValidate={forceReferralValidate}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </div>
    </PvDialog>
  );
};
