import { Box } from '@mui/material';
import React from 'react';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import { Fields } from '@pv/common/components';
import { gql, useMutation, useQuery } from '@apollo/client';
import { SectionContainer } from '../events/EventDrawer/components';
import {
  CustomFieldValuesQuery,
  CustomFieldValuesQueryVariables,
  UpdateCustomFieldValuesMutation,
  UpdateCustomFieldValuesMutationVariables,
  VenueCustomFieldsQuery,
  VenueCustomFieldsQueryVariables,
} from '@pv/common/graphql/operations';

const venueCustomFieldsQuery = gql`
  query VenueCustomFields($id: ID!) {
    venue(id: $id) {
      id
      customFields {
        id
        name
        userFieldKey
        index
        dataType
        options
        required
      }
    }
  }
`;

const customFieldValuesQuery = gql`
  query CustomFieldValues($id: ID!) {
    event(id: $id) {
      id
      customFieldValues
      canUpdate {
        value
      }
      venue {
        id
      }
    }
  }
`;

const updateCustomFieldValuesMutation = gql`
  mutation UpdateCustomFieldValues($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      event {
        id
        customFieldValues
      }
    }
  }
`;

const CustomFieldArea = ({ eventId }: { eventId: string }) => {
  const { data: eventData } = useQuery<
    CustomFieldValuesQuery,
    CustomFieldValuesQueryVariables
  >(customFieldValuesQuery, {
    variables: { id: eventId },
  });

  const [localValues, setLocalValues] = React.useState(
    eventData?.event?.customFieldValues || {}
  );

  React.useEffect(() => {
    if (eventData?.event?.customFieldValues) {
      setLocalValues(eventData.event.customFieldValues);
    }
  }, [eventData]);

  const canUpdateEvent = eventData?.event?.canUpdate?.value;

  const { data } = useQuery<
    VenueCustomFieldsQuery,
    VenueCustomFieldsQueryVariables
  >(venueCustomFieldsQuery, {
    variables: {
      id: eventData?.event?.venue?.id || '',
    },
    skip: !eventData?.event?.venue?.id,
  });

  const [updateCustomFieldValues] = useMutation<
    UpdateCustomFieldValuesMutation,
    UpdateCustomFieldValuesMutationVariables
  >(updateCustomFieldValuesMutation);

  let customFields = data?.venue?.customFields;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdate = React.useCallback(
    debounce((name: string, value: string, currentValues: any) => {
      const updatedValues = { ...currentValues, [name]: value };
      updateCustomFieldValues({
        variables: { input: { customFieldValues: updatedValues, id: eventId } },
        optimisticResponse: {
          updateEvent: {
            __typename: 'UpdateEventPayload',
            event: {
              __typename: 'Event',
              id: eventId,
              customFieldValues: updatedValues,
            },
          },
        },
      });
    }, 500),
    [updateCustomFieldValues, eventId]
  );

  const handleChange = (name: string, value: string) => {
    setLocalValues((prevValues: any) => ({ ...prevValues, [name]: value }));
    debouncedUpdate(name, value, { ...localValues, [name]: value });
  };

  return (
    <SectionContainer>
      {map(customFields, (ufd) => {
        return (
          <Box key={ufd.id}>
            <Fields.PvCustomField
              label={ufd.name}
              variant="outlined"
              userFieldDefinition={ufd}
              value={localValues[ufd.name] || ''}
              onChange={(value: any) => handleChange(ufd.name, value)}
              disabled={!canUpdateEvent}
            />
          </Box>
        );
      })}
    </SectionContainer>
  );
};

export default CustomFieldArea;
