import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Fade, IconButton, useMediaQuery, useTheme } from '@mui/material';
import some from 'lodash/some';
import size from 'lodash/size';
import { useVenues } from '../../../../../providers/venues';
import PrimaryAppBarButton from './PrimaryAppBarButton';
import PrimaryAppBarBadge from './PrimaryAppBarBadge';
import LoggedInElements from '../LoggedInElements';
import { BarChart2, Settings } from 'react-feather';

const unreadMessageCountQuery = gql`
  query ProviderVenuesUnreadMessageCount($ids: [ID!]!) {
    inboxEvents(venueIds: $ids, unread: true) {
      unreadCount
    }
  }
`;

const overdueTasksCountQuery = gql`
  query OverdueTasksCount($venueIds: [ID!]!) {
    venueTasks(venueIds: $venueIds) {
      overdueCount
    }
  }
`;

const pendingExpressBookRequestsCountQuery = gql`
  query PendingExpressBookRequestsCount($venueIds: [ID!]!) {
    pendingExpressBookRequests(venueIds: $venueIds) {
      totalCount
    }
  }
`;

const LargeScreenItems = ({ setSearchOpen, logoLength }) => {
  const { venues, venueIds, loading: venuesLoading } = useVenues();

  const premiumFeaturesEnabled = some(
    venues,
    (v) => v.featureScope?.premiumFeaturesEnabled
  );
  const basicFeaturesEnabled = some(
    venues,
    (v) => v.featureScope?.basicFeaturesEnabled
  );
  const canListReports = venues?.[0]?.canListReports.value;
  const canListMessages = venues?.[0]?.canListMessages.value;

  const noVenueIds = size(venueIds) === 0;

  const variables = { ids: venueIds };
  const pollInterval = 100000;
  const fetchPolicy = 'network-only';
  const notifyOnNetworkStatusChange = true;
  const { data } = useQuery(unreadMessageCountQuery, {
    variables,
    pollInterval,
    notifyOnNetworkStatusChange,
    fetchPolicy,
    skip: noVenueIds || !canListMessages,
  });

  const { data: overdueTasksCountData } = useQuery(overdueTasksCountQuery, {
    variables: {
      venueIds,
    },
    notifyOnNetworkStatusChange,
    fetchPolicy,
    skip: noVenueIds || !premiumFeaturesEnabled,
  });

  const { data: pendingExpressBookRequestsCountData } = useQuery(
    pendingExpressBookRequestsCountQuery,
    {
      variables: {
        venueIds,
      },
      skip: !venueIds,
    }
  );

  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const venuesSize = size(venueIds);

  const unreadMessageCount = data?.inboxEvents.unreadCount;
  const overdueTasksCount = overdueTasksCountData?.venueTasks.overdueCount;
  const pendingExpressBookRequestsCount =
    pendingExpressBookRequestsCountData?.pendingExpressBookRequests.totalCount;

  return (
    <>
      <a href={`${window.location.origin}/events`} style={{ display: 'flex' }}>
        <img
          alt="Logo"
          style={{
            width: logoLength,
            height: logoLength,
            marginRight: theme.spacing(1),
          }}
          src="/pv-logo.png"
        />
      </a>

      <PrimaryAppBarButton href="/events" name="events" label="Home" />
      {canListMessages && (
        <PrimaryAppBarBadge label={unreadMessageCount}>
          <PrimaryAppBarButton href="/inbox" name="inbox" label="Inbox" />
        </PrimaryAppBarBadge>
      )}
      <PrimaryAppBarButton
        href="/calendar"
        muted={!basicFeaturesEnabled}
        name="calendar"
        label="Calendar"
      />
      <PrimaryAppBarBadge label={overdueTasksCount}>
        <PrimaryAppBarButton
          href="/tasks"
          muted={!premiumFeaturesEnabled}
          name="tasks"
          label="Tasks"
        />
      </PrimaryAppBarBadge>
      <PrimaryAppBarBadge label={pendingExpressBookRequestsCount}>
        <PrimaryAppBarButton
          href="/express_book"
          name="express_book"
          label="Express Book"
        />
      </PrimaryAppBarBadge>
      <div style={{ flexGrow: 1 }} />
      {canListReports &&
        (mediumScreen ? (
          <IconButton
            style={{ padding: '10px' }}
            aria-label="Reports"
            title="Reports"
            href="/reports"
          >
            <span style={{ color: theme.palette.common.white }}>
              <BarChart2 />
            </span>
          </IconButton>
        ) : (
          <PrimaryAppBarButton href="/reports" name="reports" label="Reports" />
        ))}
      {!venuesLoading && (
        <Fade in={venuesSize <= 1}>
          <div>
            {mediumScreen ? (
              <IconButton
                style={{ padding: '10px' }}
                aria-label="Settings"
                title="Settings"
                href="/settings"
              >
                <span style={{ color: theme.palette.common.white }}>
                  <Settings />
                </span>
              </IconButton>
            ) : (
              <PrimaryAppBarButton
                href="/settings"
                name="settings"
                label="Settings"
              />
            )}
          </div>
        </Fade>
      )}
      <div style={{ width: theme.spacing(2) }} />
      <LoggedInElements setSearchOpen={setSearchOpen} />
    </>
  );
};

export default LargeScreenItems;
