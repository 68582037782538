import React from 'react';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

function ContactsDropdownNewContactButton({
  onClick,
  query,
}: {
  query: string;
  onClick: (s: string) => any;
}) {
  return (
    <List data-cy="contacts-dropdown-create-contact-button">
      <ListItem
        role="option"
        button
        onClick={() => onClick(query)}
        data-cy="dropdown-item"
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText>Create new contact named {query}</ListItemText>
      </ListItem>
    </List>
  );
}

export default ContactsDropdownNewContactButton;
