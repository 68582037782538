import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { useMask, format } from '@react-input/mask';

const options = {
  mask: '(___) ___-____',
  replacement: { _: /\d/ },
};

export const PhoneField = ({
  value,
  ...props
}: { value: string } & TextFieldProps) => {
  const ref = useMask(options);
  const formattedValue = format(value, options);

  return <TextField {...props} value={formattedValue} inputRef={ref} />;
};
