import {
  ClickAwayListener,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { usePublicConfig } from '@pv/common/providers';
import { useUserAuth } from '../../../../../providers/auth';
import { apiHost } from '@pv/common/api';
import { UserAvatar } from '../../../../Avatar';

const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

const useStyles = makeStyles((theme) => ({
  popper: {
    zIndex: theme.zIndex.appBar + 100,
  },
  popperContent: {
    marginTop: theme.spacing(1),
  },
}));

const AccountButton = () => {
  const { publicConfig } = usePublicConfig();
  const accountRef = useRef(null);
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { user } = useUserAuth();

  const openAccountPopover = () => setOpen(true);
  const onAccountPopoverClose = () => setOpen(false);

  return (
    <>
      <IconButton
        aria-label="Account Menu"
        title="Account Menu"
        className="account-menu-button"
        onClick={openAccountPopover}
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          marginLeft: theme.spacing(1),
        }}
        ref={accountRef}
      >
        <UserAvatar userId={user.id} />
      </IconButton>
      <Popper
        placement="bottom-start"
        anchorEl={accountRef.current}
        className={classes.popper}
        open={open}
        transition
      >
        <ClickAwayListener onClickAway={onAccountPopoverClose}>
          <Paper
            className={clsx('account-menu', classes.popperContent)}
            elevation={3}
          >
            <List>
              <ListItem button>
                <Link
                  href="/settings/profile"
                  style={{ textDecoration: 'none' }}
                >
                  <ListItemText>My Profile</ListItemText>
                </Link>
              </ListItem>
              <ListItem
                button
                onClick={() =>
                  (window.location.href = `${API_HOST}${publicConfig.paths.destroyUserSession}`)
                }
              >
                <ListItemText>Logout</ListItemText>
              </ListItem>
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export default AccountButton;
