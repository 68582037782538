import React from 'react';
import sortBy from 'lodash/sortBy';
import { startCase } from 'lodash';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

export const PaymentOptionsSelect = ({
  paymentMethods,
  selectedPaymentMethodId,
  onSelect,
  disabled = false,
}) => {
  const paymentOptions = sortBy(paymentMethods, ['brand']);

  const selectPaymentOptions = paymentOptions.map((pm) => [
    pm.id,
    `
    ${startCase(pm.brand || pm.paymentMethodType)} 
    ${pm.last4 ? `x${pm.last4}` : ''}
    ${pm.isDefault ? '(Default)' : ''}
    `,
    false,
  ]);

  return (
    <FormControl fullWidth>
      <InputLabel id="payment-method-select-label">Payment Method</InputLabel>
      <Select
        name="payment-method"
        label="Payment Method"
        labelId="payment-method-select-label"
        value={selectedPaymentMethodId}
        onChange={(e) => onSelect(e.target.value)}
        disabled={disabled}
        data-cy="payment-method-select"
      >
        {selectPaymentOptions.map(([id, label]) => (
          <MenuItem key={id} value={id}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
