import { gql, useQuery } from '@apollo/client';
import {
  EventContactsQuery,
  EventContactsQueryVariables,
} from '@pv/common/graphql/operations';
import { useEvent } from '../providers/event';

export const eventContactsQuery = gql`
  query EventContacts($eventId: ID!) {
    event(id: $eventId) {
      id
      planner {
        id
        fullName
        firstName
        lastName
        gravatarUrl
        email
        phone
        account {
          id
          name
        }
      }
      additionalContacts {
        id
        fullName
        firstName
        lastName
        gravatarUrl
        email
        phone
        account {
          id
          name
        }
      }
    }
  }
`;

export const useEventContacts = () => {
  const { event } = useEvent();
  const eventId = event?.id;

  const { data, loading } = useQuery<
    EventContactsQuery,
    EventContactsQueryVariables
  >(eventContactsQuery, {
    variables: { eventId: eventId || '' },
    skip: !eventId,
  });

  return {
    planner: data?.event?.planner,
    additionalContacts: data?.event?.additionalContacts || [],
    loading,
  };
};
