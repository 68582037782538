import { Button, Grid, Link, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { usePublicConfig } from '@pv/common/providers';
import { Fields } from '@pv/common/components';
import { DevisePage } from '../../components/DevisePage';
import { useAuthErrorMessage } from '@pv/common/hooks';
import { apiHost } from '@pv/common/api';
import { PhoneField } from '../../components/PhoneField';
const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

const Footer = () => {
  const { publicConfig } = usePublicConfig();
  return (
    <div>
      <Typography style={{ marginTop: 20 }} align="center">
        Already have an account?{' '}
        <Link href={publicConfig?.paths?.newUserSession}>Sign In</Link>
      </Typography>
    </div>
  );
};

export default function Page({
  email: initialEmail = null,
  firstName: initialFirstName = null,
  lastName: initialLastName = null,
  venueName: initialVenueName = null,
  phone: initialPhone = null,
}) {
  const [email, setEmail] = useState(initialEmail || '');
  const [phone, setPhone] = useState(initialPhone || '');
  const [firstName, setFirstName] = useState(initialFirstName || '');
  const [lastName, setLastName] = useState(initialLastName || '');
  const [venueName, setVenueName] = useState(initialVenueName || '');
  const [password, setPassword] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useAuthErrorMessage((auth) => {
    setVenueName(auth.params?.venue_name || '');
    setFirstName(auth.params?.first_name || '');
    setLastName(auth.params?.last_name || '');
    setPhone(auth.params?.phone || '');
    setEmail(auth.params?.email || '');
  });

  return (
    <DevisePage title="Perfect Venue" footer={<Footer />}>
      <form
        onSubmit={() => setSubmitDisabled(true)}
        id="sign-up-form"
        method="POST"
        action={`${API_HOST}/users`}
      >
        <Typography style={{ marginBottom: 20 }} align="center" variant="h4">
          Get started with Perfect Venue!
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Fields.PvTextField
              autoFocus
              required
              value={venueName}
              onChange={(e) => setVenueName(e.target.value)}
              placeholder="Henry's Bistro"
              name="venue_name"
              label="Venue Name"
              data-cy="sign-up-venue-name"
              inputProps={{ autoComplete: 'organization' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Fields.PvTextField
              required
              placeholder="Henry"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              name="user[first_name]"
              label="First Name"
              data-cy="sign-up-first-name"
              inputProps={{ autoComplete: 'given-name' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Fields.PvTextField
              required
              placeholder="Doodle"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              name="user[last_name]"
              label="Last Name"
              data-cy="sign-up-last-name"
              inputProps={{ autoComplete: 'family-name' }}
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneField
              label="Phone"
              name="user[phone]"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="5555555555"
              data-cy="sign-up-phone"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Fields.PvTextField
              required
              value={email}
              placeholder="events@henrysbistro.com"
              onChange={(e) => setEmail(e.target.value)}
              name="user[email]"
              label="Email"
              data-cy="sign-up-email"
              inputProps={{ autoComplete: 'email', spellCheck: false }}
            />
          </Grid>
          <Grid item xs={12}>
            <Fields.PvPasswordField
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="user[password]"
              label="Password"
              data-cy="sign-up-password"
              inputProps={{
                autoComplete: 'new-password',
                spellCheck: false,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={submitDisabled}
              fullWidth
              type="submit"
              variant="contained"
              color="secondary"
              data-cy="sign-up-submit"
            >
              Get Started
            </Button>
          </Grid>
        </Grid>
      </form>
    </DevisePage>
  );
}
