import React, { useState } from 'react';
import { gql } from '@apollo/client';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
  useTheme,
  Typography,
  Box,
} from '@mui/material';
import moment from 'moment';

import first from 'lodash/first';
import max from 'lodash/max';
import { Section } from '@pv/common/components';

import { useUserAuth } from '../providers/auth';
import UpgradeVenuePlanModal from './UpgradeVenuePlanModal';
import { useVenues } from '../providers/venues';
import { CheckCircle } from 'react-feather';
import { Elements } from '@stripe/react-stripe-js';
import { useStripeLoader } from '@pv/common/hooks';

export const contactSalesMutation = gql`
  mutation ContactSales($input: ContactSalesInput!) {
    contactSales(input: $input) {
      organization {
        id
      }
      errors {
        message
      }
    }
  }
`;

const Check = () => (
  <span style={{ color: '#00BF8F', fontSize: 28 }}>
    <CheckCircle fontSize="inherit" fontcolor="inherit" />
  </span>
);

const PricingTableCell = ({ children, ...props }) => {
  return (
    <TableCell align="center" height="50px" {...props}>
      <Typography sx={{ fontWeight: 'regular' }}>{children}</Typography>
    </TableCell>
  );
};

const LabelTableCell = ({ children, bold = false, ...props }) => {
  return (
    <TableCell align="left" height="50px" {...props}>
      <Typography sx={{ fontWeight: bold ? 'bold' : 'regular' }}>
        {children}
      </Typography>
    </TableCell>
  );
};

const SpanRow = (props) => {
  const theme = useTheme();
  return (
    <TableRow
      sx={{
        backgroundColor: theme.palette.action.hover,
      }}
      {...props}
    />
  );
};

const CheckBoxCell = ({ checked, ...props }) => {
  return (
    <TableCell align="center" height="50px" {...props}>
      {checked && <Check />}
    </TableCell>
  );
};

const AwardIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.21 13.89L7 23L12 20L17 23L15.79 13.88M19 8C19 11.866 15.866 15 12 15C8.13401 15 5 11.866 5 8C5 4.13401 8.13401 1 12 1C15.866 1 19 4.13401 19 8Z"
      stroke="#FE6257"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const PlanCell = ({
  hideButtons = false,
  planName,
  billingInterval,
  monthlyAmount,
  yearlyAmount,
  onClickSelectPlan,
  ...props
}) => {
  const theme = useTheme();

  return (
    <TableCell style={{ width: '25%' }} align="center" {...props}>
      <Typography variant="h5">{planName}</Typography>
      <div style={{ marginTop: theme.spacing(1) }}>
        <Typography display="inline" variant="h4">
          {billingInterval === 'monthly' && `$${monthlyAmount}`}
          {billingInterval === 'annual' && `$${yearlyAmount}`}
        </Typography>
        <Typography color="textSecondary" paddingLeft={'4px'}>
          per mo/location
        </Typography>
      </div>
      {!hideButtons && (
        <Button
          onClick={onClickSelectPlan}
          sx={{
            margin: theme.spacing(2),
          }}
          variant="contained"
          color="secondary"
        >
          Select Plan
        </Button>
      )}
    </TableCell>
  );
};

const PricingPanel = ({
  hideHeader = false,
  hideButtons = false,
  afterConfirm,
}) => {
  const { freeTrialEndsAt } = useUserAuth();
  const stripePromise = useStripeLoader();
  const { venues } = useVenues();
  const venue = first(venues);
  const organization = venue?.organization;
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const theme = useTheme();
  const [initialPlanName, setInitialPlanName] = useState('Basic');
  const [billingInterval, setBillingInterval] = useState('annual');
  const onClickSelectPlan =
    ({ planName }) =>
    () => {
      setOpenUpgradeModal(true);
      setInitialPlanName(planName);
    };
  let daysLeft =
    freeTrialEndsAt &&
    moment(freeTrialEndsAt)
      .startOf('day')
      .diff(moment().startOf('day'), 'days');
  daysLeft = daysLeft && max([0, daysLeft]);

  return (
    <>
      <Grid container spacing={2}>
        {!hideHeader && (
          <Grid item xs={12}>
            <Typography
              sx={{
                margin: theme.spacing(4, 2, 1, 2),
                fontSize: { xs: '28px', md: '34px' },
              }}
              variant="h4"
              align="center"
            >
              {daysLeft === 0 &&
                'Your trial has ended. Select a plan to continue.'}
              {daysLeft !== 0 && 'Select a Plan'}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={0}>
          <Box
            sx={{
              display: {
                xs: 'flex',
                md: 'none',
              },
              justifyContent: 'center',
              marginBottom: '24px',
            }}
          >
            <Button
              variant="contained"
              size="large"
              color="secondary"
              onClick={onClickSelectPlan({ planName: 'Professional' })}
            >
              Select a Plan
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              borderRadius: '8px',
              boxShadow: '0px 2px 10px 0px #0000000D',
              marginLeft: { xs: '24px', sm: '0px' },
              marginRight: { xs: '24px', sm: '0px' },
              background: '#ffffff',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: theme.spacing(3),
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: '20px', md: '24px' },
                }}
                variant="h5"
                align="center"
              >
                Have a question or want to schedule a demo?
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBottom: theme.spacing(2),
              }}
            >
              <Button
                href="https://www.perfectvenue.com/contact-sales"
                variant="contained"
                color="primary"
                data-cy="pricing-panel-contact-sales-button"
                target="_blank"
              >
                Contact Us
              </Button>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Section>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  md: 'flex',
                },
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '8px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: '24px',
                    }}
                  >
                    <div style={{ flexShrink: 1 }} />
                    <div style={{ flexGrow: 1 }}>
                      <Typography variant="h4" align="center">
                        Pricing
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ flexShrink: 1 }} />
                    <div style={{ flexGrow: 1 }}>
                      <Typography variant="body1" align="center">
                        Flexible plans that pay for themselves with just 1 more
                        booking per year
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      flexBasis: 400,
                      flexShrink: 1,
                      display: 'flex',
                      justifyContent: 'center',
                      padding: theme.spacing(2),
                      gap: '8px',
                    }}
                  >
                    <Button
                      variant="contained"
                      color={billingInterval === 'monthly' ? 'primary' : 'info'}
                      onClick={() => setBillingInterval('monthly')}
                    >
                      Monthly
                    </Button>
                    <Button
                      variant="contained"
                      color={billingInterval === 'annual' ? 'primary' : 'info'}
                      onClick={() => setBillingInterval('annual')}
                    >
                      Annual (Save $40/mo)
                    </Button>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '25%' }} />
                        <PlanCell
                          onClickSelectPlan={onClickSelectPlan({
                            planName: 'Basic',
                          })}
                          hideButtons={hideButtons}
                          planName="Basic"
                          billingInterval={billingInterval}
                          monthlyAmount={119}
                          yearlyAmount={79}
                        />
                        <PlanCell
                          onClickSelectPlan={onClickSelectPlan({
                            planName: 'Professional',
                          })}
                          hideButtons={hideButtons}
                          planName="Professional"
                          billingInterval={billingInterval}
                          monthlyAmount={199}
                          yearlyAmount={159}
                        />
                        <PlanCell
                          onClickSelectPlan={onClickSelectPlan({
                            planName: 'Premium',
                          })}
                          hideButtons={hideButtons}
                          planName="Premium"
                          billingInterval={billingInterval}
                          monthlyAmount={339}
                          yearlyAmount={299}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <SpanRow>
                        <LabelTableCell colSpan={4} bold>
                          Features
                        </LabelTableCell>
                      </SpanRow>
                      <TableRow>
                        <LabelTableCell>
                          Marketplace Visibility
                        </LabelTableCell>
                        <PricingTableCell>Low</PricingTableCell>
                        <PricingTableCell>Medium</PricingTableCell>
                        <PricingTableCell>Highest</PricingTableCell>
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Core Platform</LabelTableCell>
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Guest Portal</LabelTableCell>
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Inbox</LabelTableCell>
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Contact Form</LabelTableCell>
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Express Book</LabelTableCell>
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>AI Reply</LabelTableCell>
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>
                          No Charge for Additional Users
                        </LabelTableCell>
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Manage Multiple Venues</LabelTableCell>
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Events Calendar</LabelTableCell>
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Multiple Policies</LabelTableCell>
                        <CheckBoxCell />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Multiple F&B Minimums</LabelTableCell>
                        <CheckBoxCell />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>E-Sign Only Option</LabelTableCell>
                        <CheckBoxCell />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>
                          Floor Plan Integrations (separate subscription)
                        </LabelTableCell>
                        <CheckBoxCell />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Team Messages</LabelTableCell>
                        <CheckBoxCell />
                        <CheckBoxCell />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Task Management</LabelTableCell>
                        <CheckBoxCell />
                        <CheckBoxCell />
                        <CheckBoxCell checked />
                      </TableRow>

                      <TableRow>
                        <LabelTableCell>Embedded Contact Form</LabelTableCell>
                        <CheckBoxCell />
                        <CheckBoxCell />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>
                          Google Tag Manager Integration
                        </LabelTableCell>
                        <CheckBoxCell />
                        <CheckBoxCell />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Marketing Tracking</LabelTableCell>
                        <CheckBoxCell />
                        <CheckBoxCell />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Event Owners</LabelTableCell>
                        <CheckBoxCell />
                        <CheckBoxCell />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Multi-Day Events</LabelTableCell>
                        <CheckBoxCell />
                        <CheckBoxCell />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Log Activity</LabelTableCell>
                        <CheckBoxCell />
                        <CheckBoxCell />
                        <CheckBoxCell checked />
                      </TableRow>

                      <SpanRow>
                        <LabelTableCell colSpan={4} bold>
                          Payments
                        </LabelTableCell>
                      </SpanRow>
                      <TableRow>
                        <LabelTableCell>
                          <Link
                            underline="always"
                            href="https://help.perfectvenue.com/knowledge/payment-processing-options-with-perfect-venue"
                          >
                            Processing Fee
                          </Link>
                           - Includes Amex!
                        </LabelTableCell>
                        <PricingTableCell>0.9%</PricingTableCell>
                        <PricingTableCell>0.3%</PricingTableCell>
                        <PricingTableCell>0%</PricingTableCell>
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>ACH Processing</LabelTableCell>
                        <PricingTableCell></PricingTableCell>
                        <PricingTableCell></PricingTableCell>
                        <CheckBoxCell checked />
                      </TableRow>
                      <SpanRow>
                        <LabelTableCell colSpan={4} bold>
                          Support & Onboarding
                        </LabelTableCell>
                      </SpanRow>
                      <TableRow>
                        <LabelTableCell>
                          Account Setup, Migration & Training
                        </LabelTableCell>
                        <PricingTableCell>Self-Serve</PricingTableCell>
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                      <TableRow>
                        <LabelTableCell>Phone Support</LabelTableCell>
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                        <CheckBoxCell checked />
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: '20px',
                      gap: '10px',
                    }}
                  >
                    <AwardIcon />
                    <Typography variant="h6">
                      Risk-Free 30 Day Money Back Guarantee for All Plans
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Section>
        </Grid>
      </Grid>
      <div>
        <Elements stripe={stripePromise}>
          <UpgradeVenuePlanModal
            venue={venue}
            organizationId={organization?.id}
            open={openUpgradeModal}
            onClose={() => setOpenUpgradeModal(false)}
            initialBillingInterval={billingInterval}
            initialTier={initialPlanName?.toLowerCase()}
            afterConfirm={afterConfirm}
          />
        </Elements>
      </div>
    </>
  );
};

export default PricingPanel;
