import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { useUserAuth } from '../../providers/auth';
import { useVenues } from '../../providers/venues';
import {
  InboxEventsQuery,
  InboxEventsQueryVariables,
  UnreadLatestVenueEventMessagesMutation,
  UnreadLatestVenueEventMessagesMutationVariables,
  ReadAllVenueEventMessagesMutation,
  ReadAllVenueEventMessagesMutationVariables,
  ReadManyEventMessagesMutation,
  ReadManyEventMessagesMutationVariables,
  UnreadManyEventMessagesMutation,
  UnreadManyEventMessagesMutationVariables,
} from '@pv/common/graphql/operations';
import { inboxEventsQuery } from '../queries';
const unreadLatestByVenueMutation = gql`
  mutation UnreadLatestVenueEventMessages(
    $input: UnreadLatestVenueEventMessagesInput!
  ) {
    unreadLatestVenueEventMessages(input: $input) {
      errors {
        message
      }
    }
  }
`;

const readAllByVenueMutation = gql`
  mutation ReadAllVenueEventMessages($input: ReadAllVenueEventMessagesInput!) {
    readAllVenueEventMessages(input: $input) {
      errors {
        message
      }
    }
  }
`;

const readAllMutation = gql`
  mutation ReadManyEventMessages($input: ReadAllEventMessagesInput!) {
    readAllEventMessages(input: $input) {
      events {
        id
      }
      errors {
        message
      }
    }
  }
`;

const unreadLatestMutation = gql`
  mutation UnreadManyEventMessages($input: UnreadLatestEventMessagesInput!) {
    unreadLatestEventMessages(input: $input) {
      events {
        id
      }
      errors {
        message
      }
    }
  }
`;

export const InboxToolbar = ({
  eventCount = 0,
  unreadEventCount = 0,
  selected,
  allSelected,
  ownerIds,
  setOwnerIds,
  showUnreadOnly,
  setShowUnreadOnly,
  premiumFeaturesEnabled,
}: {
  eventCount?: number;
  unreadEventCount?: number;
  selected: Record<number, boolean>;
  allSelected: boolean;
  ownerIds?: string[];
  setOwnerIds: (ownerIds: string[] | undefined) => void;
  showUnreadOnly: boolean;
  setShowUnreadOnly: (val: boolean) => void;
  premiumFeaturesEnabled: boolean;
}) => {
  const theme = useTheme();
  const largeScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const { venueIds } = useVenues();
  const { user } = useUserAuth();

  const [readAllEventMessages] = useMutation<
    ReadManyEventMessagesMutation,
    ReadManyEventMessagesMutationVariables
  >(readAllMutation, {
    update(cache, { data }) {
      if (!data || !data.readAllEventMessages) return;
      const { events } = data.readAllEventMessages;
      const eventIds = events.map((event) => event.id);

      const cachedQuery = cache.readQuery<
        InboxEventsQuery,
        InboxEventsQueryVariables
      >({ query: inboxEventsQuery });

      if (!cachedQuery) return;

      const { inboxEvents } = cachedQuery;
      const { edges } = inboxEvents;
      if (!edges) return;

      const updatedEdges = edges.map((edge) => {
        if (!edge || !edge.node) return edge;

        if (eventIds.includes(edge.node.id)) {
          return {
            ...edge,
            node: {
              ...edge.node,
              unreadMessageCount: 0,
            },
          };
        }
        return edge;
      });

      cache.writeQuery<InboxEventsQuery, InboxEventsQueryVariables>({
        query: inboxEventsQuery,
        variables: { venueIds, ownerIds, unread: showUnreadOnly },
        data: {
          inboxEvents: {
            ...inboxEvents,
            edges: updatedEdges,
          },
        },
      });
    },
  });

  const [unreadLatestEventMessages] = useMutation<
    UnreadManyEventMessagesMutation,
    UnreadManyEventMessagesMutationVariables
  >(unreadLatestMutation, {
    update(cache, { data }) {
      if (!data || !data.unreadLatestEventMessages) return;
      const { events } = data.unreadLatestEventMessages;
      const eventIds = events.map((event) => event.id);

      const cachedQuery = cache.readQuery<
        InboxEventsQuery,
        InboxEventsQueryVariables
      >({ query: inboxEventsQuery });

      if (!cachedQuery) return;

      const { inboxEvents } = cachedQuery;
      const { edges } = inboxEvents;
      if (!edges) return;

      const updatedEdges = edges.map((edge) => {
        if (!edge || !edge.node) return edge;

        if (eventIds.includes(edge.node.id)) {
          return {
            ...edge,
            node: {
              ...edge.node,
              unreadMessageCount: 1,
            },
          };
        }
        return edge;
      });

      cache.writeQuery<InboxEventsQuery, InboxEventsQueryVariables>({
        query: inboxEventsQuery,
        variables: { venueIds, ownerIds, unread: showUnreadOnly },
        data: {
          inboxEvents: {
            ...inboxEvents,
            edges: updatedEdges,
          },
        },
      });
    },
  });

  const [readAllVenueEventMessages] = useMutation<
    ReadAllVenueEventMessagesMutation,
    ReadAllVenueEventMessagesMutationVariables
  >(readAllByVenueMutation, {
    update(cache) {
      const cachedQuery = cache.readQuery<
        InboxEventsQuery,
        InboxEventsQueryVariables
      >({ query: inboxEventsQuery });

      if (!cachedQuery) return;

      const { inboxEvents } = cachedQuery;
      const { edges } = inboxEvents;
      if (!edges) return;

      const updatedEdges = edges.map((edge) => {
        if (!edge || !edge.node) return edge;

        return {
          ...edge,
          node: {
            ...edge.node,
            unreadMessageCount: 0,
          },
        };
      });

      cache.writeQuery<InboxEventsQuery, InboxEventsQueryVariables>({
        query: inboxEventsQuery,
        variables: { venueIds, ownerIds, unread: showUnreadOnly },
        data: {
          inboxEvents: {
            ...inboxEvents,
            unreadCount: 0,
            edges: updatedEdges,
          },
        },
      });
    },
  });

  const [unreadLatestVenueEventMessages] = useMutation<
    UnreadLatestVenueEventMessagesMutation,
    UnreadLatestVenueEventMessagesMutationVariables
  >(unreadLatestByVenueMutation, {
    update(cache) {
      const cachedQuery = cache.readQuery<
        InboxEventsQuery,
        InboxEventsQueryVariables
      >({ query: inboxEventsQuery });

      if (!cachedQuery) return;

      const { inboxEvents } = cachedQuery;
      const { edges } = inboxEvents;
      if (!edges) return;

      const updatedEdges = edges.map((edge) => {
        if (!edge || !edge.node) return edge;

        return {
          ...edge,
          node: {
            ...edge.node,
            unreadCount: inboxEvents.totalCount,
            unreadMessageCount: 1,
          },
        };
      });

      cache.writeQuery<InboxEventsQuery, InboxEventsQueryVariables>({
        query: inboxEventsQuery,
        variables: { venueIds, ownerIds, unread: showUnreadOnly },
        data: { inboxEvents: { ...inboxEvents, edges: updatedEdges } },
      });
    },
  });

  const markAsUnread = useCallback(() => {
    if (allSelected) {
      unreadLatestVenueEventMessages({
        variables: { input: { venueIds, exclude: Object.keys(selected) } },
      });
    } else {
      unreadLatestEventMessages({
        variables: { input: { ids: Object.keys(selected) } },
      });
    }
  }, [
    selected,
    unreadLatestEventMessages,
    allSelected,
    unreadLatestVenueEventMessages,
    venueIds,
  ]);

  const markAsRead = useCallback(() => {
    if (allSelected) {
      readAllVenueEventMessages({
        variables: { input: { venueIds, exclude: Object.keys(selected) } },
      });
    } else {
      readAllEventMessages({
        variables: { input: { ids: Object.keys(selected) } },
      });
    }
  }, [
    selected,
    readAllEventMessages,
    allSelected,
    readAllVenueEventMessages,
    venueIds,
  ]);

  const totalSelected = useMemo(() => {
    if (!allSelected) {
      return Object.keys(selected).length;
    } else {
      return eventCount - Object.keys(selected).length;
    }
  }, [allSelected, eventCount, selected]);

  const eventCopy = eventCount === 1 ? 'event' : 'events';

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '20px 24px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
          height: largeScreen ? '74px' : 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: largeScreen ? 'center' : 'left',
            gap: largeScreen ? '18px' : 0,
            flexDirection: largeScreen ? 'row' : 'column',
          }}
        >
          <Typography variant="h5">Inbox</Typography>
          <Typography variant="body1" color="text.secondary">
            {unreadEventCount} unread, {eventCount} {eventCopy}
          </Typography>
        </Box>
        {largeScreen && totalSelected > 0 && (
          <Box sx={{ display: 'flex', gap: '18px', alignItems: 'center' }}>
            <Typography variant="body1">
              {totalSelected} {eventCopy} selected
            </Typography>
            <Button
              disabled={totalSelected === 0}
              variant="outlined"
              color="error"
              onClick={markAsUnread}
            >
              Mark as Unread
            </Button>
            <Button
              disabled={totalSelected === 0}
              variant="outlined"
              color="error"
              onClick={markAsRead}
            >
              Mark as Read
            </Button>
          </Box>
        )}
        {totalSelected === 0 && user && (
          <Box
            sx={{
              display: 'flex',
              gap: largeScreen ? '18px' : '12px',
              alignItems: largeScreen ? 'center' : 'start',
            }}
          >
            {premiumFeaturesEnabled && (
              <Button
                variant="outlined"
                color="error"
                size={largeScreen ? 'medium' : 'small'}
                onClick={() => {
                  ownerIds ? setOwnerIds(undefined) : setOwnerIds([user.id]);
                }}
              >
                {ownerIds ? 'All Events' : 'My Events'}
              </Button>
            )}
            <Button
              variant="outlined"
              color="error"
              size={largeScreen ? 'medium' : 'small'}
              onClick={() => setShowUnreadOnly(!showUnreadOnly)}
            >
              {showUnreadOnly ? 'Show Read' : 'Hide Read'}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};
