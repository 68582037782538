import { Box, Button, Grid, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import queryString from 'query-string';
import { Fields } from '@pv/common/components';
import FlashSnackbars from '../../components/FlashSnackbars';
import { useAuthErrorMessage } from '@pv/common/hooks';
import { apiHost } from '@pv/common/api';
import { GlobalStyles } from '@mui/styled-engine';
import { Helmet } from 'react-helmet';
import { PhoneField } from '../../components/PhoneField';
const API_HOST = apiHost(process.env.REACT_APP_PV_ENV);

const HiddenInvitationToken = () => {
  const params = queryString.parse(window.location.search);
  const value = params.invitation_token;
  return <input type="hidden" name="user[invitation_token]" value={value} />;
};

export default function AcceptInvitationPage() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [email, setEmail] = useState('');

  useAuthErrorMessage();
  const theme = useTheme();

  return (
    <>
      <GlobalStyles
        styles={{
          html: { height: '100%' },
          body: { backgroundColor: theme.palette.white, height: '100%' },
        }}
      />
      <Helmet>
        <title>Invitation | Perfect Venue</title>
      </Helmet>
      <Box
        sx={{
          boxSizing: 'border-box',
          height: { md: 'auto', lg: '100vh' },
          width: '100vw',
          overflowY: 'hidden',
          overflowX: 'hidden',
          display: 'flex',
        }}
        data-cy="invitation-form-page"
      >
        <Box
          sx={{
            height: '100%',
            width: { xs: '100%', sm: '100%', md: '100%', lg: '50%' },
            overflowY: 'auto',
            padding: { xs: 4, sm: 4, md: 4, lg: 8 },
            paddingBottom: { xs: 4, sm: 4, md: 4, lg: 8 },
            margin: 0,
          }}
        >
          <Box
            component="img"
            src="/pv-logo-name.png"
            sx={{
              height: 40,
              width: 228,
              marginBottom: '36px',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <FlashSnackbars>
              <form method="POST" action={`${API_HOST}/users/invitation`}>
                <input type="hidden" name="_method" value="put" />
                <HiddenInvitationToken />
                <Typography sx={{ marginBottom: '10px' }} variant="h4">
                  Set up your account
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '20px',
                    lineHeight: '30px',
                    marginBottom: '36px',
                  }}
                >
                  Get ready to pop the champagne!
                </Typography>
                <img
                  src="/squiggle.svg"
                  alt="squiggle"
                  style={{
                    marginBottom: '36px',
                  }}
                />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Fields.PvTextField
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="user[email]"
                      label="Email"
                      required
                      inputProps={{ autoComplete: 'email' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Fields.PvTextField
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      name="user[first_name]"
                      label="First name"
                      required
                      inputProps={{ autoComplete: 'given-name' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Fields.PvTextField
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      name="user[last_name]"
                      label="Last name"
                      required
                      inputProps={{ autoComplete: 'family-name' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneField
                      label="Phone"
                      name="user[phone]"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Fields.PvPasswordField
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      name="user[password]"
                      label="Password"
                      required
                      inputProps={{ autoComplete: 'new-password' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Fields.PvPasswordField
                      value={passwordConfirmation}
                      onChange={(e) => setPasswordConfirmation(e.target.value)}
                      name="user[password_confirmation]"
                      label="Password Confirmation"
                      required
                      inputProps={{ autoComplete: 'new-password' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      By clicking next, you agree to our{' '}
                      <a
                        href="https://www.perfectvenue.com/terms-of-service"
                        target="_blank"
                        rel="noreferrer"
                      >
                        terms of service
                      </a>
                      .
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      disabled={
                        !firstName ||
                        !email ||
                        !lastName ||
                        !password ||
                        !passwordConfirmation
                      }
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="secondary"
                      sx={{
                        marginTop: '133px',
                      }}
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </FlashSnackbars>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: '#F7F0ED',
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
          }}
          data-cy="onboarding-form-page-background"
        >
          <Box
            sx={{
              display: 'flex',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <img
              src="/onboarding-assets/image-events-program.png"
              style={{
                maxWidth: '600px',
              }}
              alt="events-program"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
