import * as React from 'react';
import { Button, Menu, MenuItem, Box } from '@mui/material';
import { MoreHorizontal as More } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';

export default function BonusReportsMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        id="more-reports-button"
        aria-controls={open ? 'bonus-reports-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          padding: '9px 16px',
          lineHeight: '24px',
          borderRadius: 0,
          height: '100%',
          borderTop: `2px solid transparent`,
          borderBottom: `2px solid transparent`,
        }}
      >
        <More size={20} color={'rgba(59, 57, 108, 0.6)'} />
      </Button>
      <Menu
        id="bonus-reports-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'more-reports-button',
        }}
      >
        <MenuItem
          component={RouterLink}
          to="/reports/deleted-events"
          onClick={handleClose}
        >
          Deleted Events
        </MenuItem>
      </Menu>
    </Box>
  );
}
