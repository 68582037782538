import { Checkbox, Chip, MenuItem } from '@material-ui/core';
import { gql } from '@apollo/client';

import { Slash as NopeIcon, AlertCircle } from 'react-feather';
import { makeStyles } from '@material-ui/styles';
import keyBy from 'lodash/keyBy';
import each from 'lodash/each';
import compact from 'lodash/compact';
import map from 'lodash/map';
import join from 'lodash/join';
import isArray from 'lodash/isArray';
import find from 'lodash/find';
import React from 'react';
import { Fields } from '@pv/common/components';

import { danger, success, warn, orange, PVLightGray } from '@pv/common/colors';

const useStyles = makeStyles((theme) => ({
  orangeStatusChip: {
    backgroundColor: orange.backgroundColorLight,
    color: orange.textColor,
    borderColor: orange.textColor,
  },
  greenStatusChip: {
    backgroundColor: success.backgroundColorLight,
    color: success.textColor,
    borderColor: success.textColor,
  },
  warnStatusChip: {
    backgroundColor: warn.backgroundColorLight,
    color: warn.textColor,
    borderColor: warn.textColor,
  },
  dangerStatusChip: {
    backgroundColor: danger.backgroundColorLight,
    color: danger.textColor,
    borderColor: danger.textColor,
  },
  noConflictStatusChip: {
    backgroundColor: 'transparent',
    color: theme.palette.text.primary,
    borderColor: PVLightGray,
    border: '1px solid',
  },
  iconWrapper: {
    marginRight: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 16,
    height: 16,
  },
  menuItemTextWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
  },
  menuItemChipStyles: {
    margin: 2,
    maxWidth: '100%',
    truncate: true,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '4px',
  },
}));

export const spaceValsFromVenue = ({ venue }) => {
  const spaceById = keyBy(venue?.spaces, (s) => s.id);
  let spaces = [];
  const addSpaces = ({ node, depth = 0 }) => {
    spaces = [...spaces, { id: node.id.toString(), depth }];
    each(node.children, (n) => addSpaces({ node: n, depth: depth + 1 }));
  };
  each(venue?.spaceGraph, (node) => {
    addSpaces({ node });
  });

  return compact(
    map(spaces, (space) => {
      const s = spaceById[space.id];
      if (s) {
        let name = s?.name;
        if (s?.seated > 0 || s?.standing > 0) {
          name = `${name} (${join(
            compact([
              s?.seated?.toLocaleString(),
              s?.standing?.toLocaleString(),
            ]),
            ' - '
          )})`;
        }
        return [
          space.id.toString(),
          name,
          false,
          { paddingLeft: `${space.depth * 20}px` },
          spaceById[space.id]?.ignoreConflicts,
        ];
      }
    })
  );
};

export const spaceAvailabilityQuery = gql`
  query SpaceAvailability($venueId: ID!, $dateTimeRange: DateTimeRangeInput!) {
    spaceAvailability(venueId: $venueId, dateTimeRange: $dateTimeRange)
  }
`;

const IconWrapper = ({ color, children }) => {
  const classes = useStyles();
  return (
    <span
      className={classes.iconWrapper}
      style={{
        color,
      }}
    >
      {children}
    </span>
  );
};

const AlertIcon = () => (
  <IconWrapper color={orange.backgroundColor}>
    <AlertCircle fontcolor="inherit" />
  </IconWrapper>
);

const ErrorIcon = () => (
  <IconWrapper color={danger.backgroundColor}>
    <NopeIcon fontcolor="inherit" />
  </IconWrapper>
);

const SpaceSelector = ({
  spaceAvailability,
  standardRenderValue = false,
  standardRenderOption = false,
  vals,
  value,
  onChange,
  label,
  ...props
}) => {
  const classes = useStyles();
  const makeOption = (option) => {
    const v = isArray(option) ? option[0] : option;
    const title = isArray(option) ? option[1] : option;
    const disabled = isArray(option) ? option[2] : false;
    const style = isArray(option) ? { ...option[3] } : {};
    const ignoreConflicts = option[4];
    let color;
    let chipStyles;
    let icon;
    if (ignoreConflicts) {
      color = success.textColor;
      chipStyles = classes.noConflictStatusChip;
    } else if (!spaceAvailability) {
      color = warn.textColor;
      chipStyles = classes.warnStatusChip;
      icon = <AlertIcon />;
    } else if (spaceAvailability[v] === 'available') {
      color = success.textColor;
      chipStyles = classes.greenStatusChip;
      icon = <></>;
    } else if (spaceAvailability[v] === 'soft_block') {
      color = warn.textColor;
      chipStyles = classes.warnStatusChip;
      icon = <AlertIcon />;
    } else if (spaceAvailability[v] === 'hard_block') {
      color = danger.textColor;
      chipStyles = classes.dangerStatusChip;
      icon = <ErrorIcon />;
    }
    let labelWithIcon = (
      <span className={classes.menuItemTextWrapper}>
        {title}
        {icon}
      </span>
    );
    return (
      <MenuItem
        key={v}
        value={v}
        disabled={disabled}
        dense
        style={{
          ...style,
          paddingTop: 0,
          paddingBottom: 0,
          backgroundColor: 'transparent',
        }}
        data-cy="dropdown-item"
        role="option"
      >
        <Checkbox
          color="primary"
          checked={!!(value && value.indexOf(v) > -1)}
        />
        <Chip
          classes={{ root: chipStyles }}
          sx={{
            color,
            margin: 2,
            maxWidth: '100%',
            truncate: true,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            padding: '4px',
          }}
          label={labelWithIcon}
        />
      </MenuItem>
    );
  };

  const renderValue = (selected) => {
    const selectedSpaces = map(selected, (selectedItem) =>
      find(vals, (s) => s[0]?.toString() === selectedItem?.toString())
    ).filter((s) => !!s);

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {map(selectedSpaces, (space) => {
          let chipStyles;
          let icon;
          let color;
          let onDelete = () => {};
          const ignoreConflicts = space[4];
          if (ignoreConflicts) {
            onDelete = null;
            chipStyles = classes.noConflictStatusChip;
            color = success.textColor;
            icon = <></>;
          } else if (!spaceAvailability) {
            chipStyles = classes.warnStatusChip;
            color = orange.textColor;
            icon = <AlertIcon />;
          } else if (spaceAvailability[space[0]] === 'available') {
            chipStyles = classes.greenStatusChip;
            color = success.textColor;
            icon = <></>;
          } else if (spaceAvailability[space[0]] === 'soft_block') {
            chipStyles = classes.warnStatusChip;
            color = orange.textColor;
            icon = <AlertIcon />;
          } else if (spaceAvailability[space[0]] === 'hard_block') {
            chipStyles = classes.dangerStatusChip;
            color = danger.textColor;
            icon = <ErrorIcon />;
          }

          return (
            <Chip
              classes={{
                root: chipStyles,
              }}
              deleteIcon={icon}
              onDelete={onDelete}
              key={space[0]}
              label={space[1]}
              style={{
                color,
                margin: 2,
                maxWidth: '100%',
                truncate: true,
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '4px',
              }}
            />
          );
        })}
      </div>
    );
  };

  return (
    <Fields.PvMultiSelect
      emptyText="Unassigned"
      renderValue={!standardRenderValue && renderValue}
      makeOption={!standardRenderOption && makeOption}
      vals={vals}
      onChange={onChange}
      value={value}
      label={label}
      sx={{
        '.MuiSelect &:focus': {
          backgroundColor: 'transparent',
        },
      }}
      data-cy="create-event-space-multiselect"
      {...props}
    />
  );
};

export default SpaceSelector;
